import { GovtechDigitalAcademyCertificateOfAchievement_2SigneesTemplate } from './certificate-of-achievement-2-signees.template'

export const govtechDigitalAcademyCertificateOfAchievement_2SigneesTemplates = [
    {
        id: 'govtechDigitalAcademyCertificateOfAchievement_2Signees',
        label: 'Certificate',
        template:
            GovtechDigitalAcademyCertificateOfAchievement_2SigneesTemplate,
    },
]
