import { GovtechDigitalAcademyCertificateOfParticipationV2_2SigneesTemplate } from './certificate-of-participation-v2-2-signees.template'

export const govtechDigitalAcademyCertificateOfParticipationV2_2SigneesTemplates =
    [
        {
            id: 'govtechDigitalAcademyCertificateOfParticipationV2_2Signees',
            label: 'Certificate',
            template:
                GovtechDigitalAcademyCertificateOfParticipationV2_2SigneesTemplate,
        },
    ]
