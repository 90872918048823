import { GovtechDigitalAcademyCertificateOfCompletionSmartnationTemplate } from './certificate-of-completion-smartnation.template'

export const govtechDigitalAcademyCertificateOfCompletionSmartnationTemplates =
    [
        {
            id: 'govtechDigitalAcademyCertificateOfCompletionSmartnation',
            label: 'Certificate',
            template:
                GovtechDigitalAcademyCertificateOfCompletionSmartnationTemplate,
        },
    ]
