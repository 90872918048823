import { GovtechDigitalAcademyCertificateOfParticipation_1SigneeTemplate } from './certificate-of-participation-1-signee.template'

export const govtechDigitalAcademyCertificateOfParticipation_1SigneeTemplates =
    [
        {
            id: 'govtechDigitalAcademyCertificateOfParticipation_1Signee',
            label: 'Certificate',
            template:
                GovtechDigitalAcademyCertificateOfParticipation_1SigneeTemplate,
        },
    ]
