import { GovtechDigitalAcademyCertificateOfParticipation_2SigneesTemplate } from './certificate-of-participation-2-signees.template'

export const govtechDigitalAcademyCertificateOfParticipation_2SigneesTemplates =
    [
        {
            id: 'govtechDigitalAcademyCertificateOfParticipation_2Signees',
            label: 'Certificate',
            template:
                GovtechDigitalAcademyCertificateOfParticipation_2SigneesTemplate,
        },
    ]
