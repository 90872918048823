import { GovtechDigitalAcademyCertificateOfParticipationV2_1SigneeTemplate } from './certificate-of-participation-v2-1-signee.template'

export const govtechDigitalAcademyCertificateOfParticipationV2_1SigneeTemplates =
    [
        {
            id: 'govtechDigitalAcademyCertificateOfParticipationV2_1Signee',
            label: 'Certificate',
            template:
                GovtechDigitalAcademyCertificateOfParticipationV2_1SigneeTemplate,
        },
    ]
