import { GovtechDigitalAcademyCertificateOfAchievementSmartnationTemplate } from './certificate-of-achievement-smartnation.template'

export const govtechDigitalAcademyCertificateOfAchievementSmartnationTemplates =
    [
        {
            id: 'govtechDigitalAcademyCertificateOfAchievementSmartnation',
            label: 'Certificate',
            template:
                GovtechDigitalAcademyCertificateOfAchievementSmartnationTemplate,
        },
    ]
